import React from 'react';
import './DemoOverlay.css'; // Import the CSS for styling
const DemoOverlay = () => {
    return (
        <div className="demo-overlay">
            <p>Demo site</p>
            <p>For Reference Only</p>
        </div>
    );
};
export default DemoOverlay;